<template>
  <!-- 虚拟仿真实验证书 -->
  <div class="mainpage">
    <div class="head">
      <div class="head-title">虚拟仿真实验证书</div>
      <div class="retutnbt" @click="toback">返回</div>
    </div>
    <div class="line"></div>
    <div class="maincontain">
      <div class="searchcontain">
        <div class="left">
          <div style="margin-left: 15px" class="maintext">关键字:</div>

          <el-input class="elinput" placeholder="请输入证书名称" v-model="queryParams.keyword" clearable>
          </el-input>
        </div>
        <div class="right">
          <div class="bt searchbt" @click="searchbt">查询</div>
          <div class="bt restbt" @click="resetbt">重置</div>
        </div>
      </div>
      <!-- 列表渲染 -->
      <el-table ref="singleTable" :data="tableData" highlight-current-row style="width: 100%" class="Table" stripe
        :header-cell-style="{
          'font-size': '15px',
          color: '#666666',
          'font-weight': 'bold',
          background: '#FFFFFF',
        }" :row-style="{
          'font-size': '15px',
          color: '#222222',
          'font-weight': '400',
        }">
        <el-table-column prop="certificate_sn" label="证书编号"> </el-table-column>
        <el-table-column prop="vr_name" label="证书名称"> </el-table-column>
        <el-table-column prop="send_date" label="获得时间">
          <!-- <template slot-scope="scope">
            {{ send_date }}
          </template> -->
        </el-table-column>
        <el-table-column prop="expiry_date" label="有效期">
          <template slot-scope="scope">
            {{ scope.row.expiry_date | dataformat }}
          </template>
        </el-table-column>

        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button class="opreatbt" @click="viewcertificate(scope.row)">查看证书</el-button>
          </template>
        </el-table-column>
      </el-table>

      <div class="elpagination">
        <div class="elpagination-title">共{{ total }}条</div>
        <el-pagination background layout="prev, pager, next" :total="total" :page-size="10"
          :current-page="queryParams.page" @current-change="pageChange">
        </el-pagination>
      </div>
    </div>
    <!--  证书详情弹窗 -->
    <!-- <el-dialog title="证书查看" :visible.sync="dialogVisible" width="40%" :before-close="handleClose">
      <iframe :src="certificate_url" frameborder="0" style="width: 100%; height: 100vh"></iframe>
    </el-dialog> -->
  </div>
</template>

<script>
import dayjs from "dayjs";
import {
  getvrstudycertificate,
  getvrstudycertificateinfo,
} from "@/api/vrstudycertificate";
export default {
  name: "mainpage",
  data() {
    return {
      total: 0,
      opreatpanelstate: false,
      radio: "1",
      info: "",
      queryParams: {
        vr_id: '',
        keyword: "",
        page: 1,
        per_page: 10
      },
      logoimage: require("@/assets/logo.png"),
      testimage: require("@/assets/test.png"),
      icon1: require("@/assets/coursedetails/文件类型-标准图-视频文件.png"),
      icon2: require("@/assets/coursedetails/矢量智能对象 (1).png"),
      tableData: [],
      updated_at: '',
      options: [],
      value: "",
      certificate_url: '',
    };
  },
  filters: {
    dataformat: function (value) {
      if (!value) return "";
      return dayjs.unix(value).format("YYYY-MM-DD");
    },
    // dataformat1: function (value) {
    //   if (!value) return "";
    //   return dayjs.unix(value).format("YYYY-MM-DD ");
    // },
  },
  computed: {},
  created() {
    this.queryParams.vr_id = this.$route.query.vr_id
    this.getvrstudycertificate();
  },
  methods: {
    resetbt() {
      this.queryParams.keyword = "";
      this.getvrstudycertificate();
    },
    searchbt() {
      this.getvrstudycertificate();
    },
    pageChange(val) {
      this.queryParams.page = val
      this.getvrstudycertificate();
    },
    toback() {
      this.$router.push({
        path: "/home/personalcenter/mainpage",
        query: {
          id: 0,
          componentId: "Virtualsimulationexperiment",
        },
      });
    },
    viewcertificate(item) {
      // this.dialogVisible = true
      // this.$refs.customdialog.dialogopenbt();
      // console.log("itemitemitem", item);
      // this.$set(this, 'certificate_url', item.certificate_url)
      // this.certificate_url = item.certificate_url
      window.open(item.certificate_url)
      // this.getvrstudycertificateinfo({
      //   id: item.id,
      // });
    },
    opreatbt() {
      if (this.opreatpanelstate) {
        this.opreatpanelstate = false;
      } else {
        this.opreatpanelstate = true;
      }
    },
    todetail() {
      this.$router.push({
        path: "/openexperiment/experimentdetails",
      });
    },

    getvrstudycertificate(params) {
      getvrstudycertificate(params)
        .then((response) => {
          this.total = response.data.total;
          this.tableData = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getvrstudycertificateinfo(params) {
      getvrstudycertificateinfo(params)
        .then((response) => {
          this.updated_at = response.data.updated_at
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
<style scoped lang="scss">

.mainpage {
  background: #ffffff;
  min-height: calc(100vh - 240px);

  .head {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 20px;
    padding-top: 10px;
    height: 40px;

    .head-title {
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: 400;
      // color: #288add;
      user-select: none;
      white-space: nowrap;
    }

    .retutnbt {
      width: 64px;
      height: 38px;
      line-height: 38px;
      text-align: center;
      background: #3d84ff;
      border-radius: 4px;
      color: white;
      user-select: none;
      cursor: pointer;
      margin-right: 20px;
      font-size: 14px;
    }
  }

  .line {
    margin-top: 14px;
    margin-bottom: 4px;
    border-bottom: 1px solid #cccccc;
  }

  .maincontain {
    background: #ffffff;
    border-radius: 4px;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;

    .searchcontain {
      height: 56px;
      background: #fcfcfc;
      border: 1px solid #ececec;
      display: flex;
      justify-content: space-between;
      align-items: center;
      // padding-left: 20px;
      padding-right: 20px;

      .left {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .maintext {
          margin-right: 10px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #888888;
        }

        .elinput {
          width: 200px;

          ::v-deep .el-input__suffix {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }

      .right {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .bt {
          width: 64px;
          height: 38px;
          line-height: 38px;
          text-align: center;
          background: #ffffff;
          border: 1px solid #3d84ff;
          border-radius: 2px;
          user-select: none;
          cursor: pointer;
          font-size: 14px;
        }

        .searchbt {
          background: #3d84ff;
          color: white;
          margin-right: 10px;
        }

        .restbt {

          border: 1px solid #3d84ff;
          color: #3d84ff;
        }
      }
    }

    .Table {
      width: 100%;

      // border: 1px solid red;
      .btgroup {
        .bt {
          border: none;
        }
      }

      .opreatbt {
        border: none;
        background: none;
        margin: 0px;
        padding: 0px;
        color: #3d84ff;
      }
    }

    .elpagination {
      margin-top: 20px;
      margin-bottom: 40px;
      display: flex;
      justify-content: center;
      align-items: center;

      .elpagination-title {
        margin-right: 4px;
      }
    }
  }

  .customdialog {
    // display: flex;
    // flex-direction: column;

    height: 100%;

    .dialogbody {
      // border: 1px solid red;
      text-align: left;
      position: relative;
      // height: 1000px;
      height: 80%;
      width: 100%;

      .backimage {
        width: 70%;
        height: 100%;
        // position: absolute;
        // top: 0px;
        // right: 0px;
        // left: 0px;
        // bottom: 0px;
        left: 16%;
      }

      .text_contain {
        width: 60%;
        position: absolute;
        padding-left: 6%;
        // padding-right: 10%;
        top: 38%;
        // right: 0px;
        left: 16%;
        line-height: 40px;
        font-size: 18px;
        font-weight: 500;
        color: #857c73;

        span {
          font-size: 20px;
          font-weight: 600;
          text-decoration: underline;
          text-underline-offset: 6px;
        }
      }

      .text_contain2 {
        width: 60%;
        position: absolute;
        padding-left: 10%;
        padding-right: 10%;
        bottom: 35%;
        right: 0px;
        left: 16%;
        line-height: 40px;
        font-size: 18px;
        font-weight: 500;
        color: #857c73;

        span {
          font-size: 20px;
          font-weight: 600;
        }
      }

      .text_contain3 {
        width: 60%;
        position: absolute;
        padding-left: 10%;
        padding-right: 10%;
        bottom: 20%;
        right: 0px;
        left: 16%;
        line-height: 40px;
        font-size: 18px;
        font-weight: 500;
        color: #857c73;

        span {
          font-size: 20px;
          font-weight: 400;
        }
      }

      .text_contain4 {
        position: absolute;
        padding-left: 10%;
        padding-right: 10%;
        bottom: 10%;
        right: 12%;
        // left: 0px;
        line-height: 40px;
        font-size: 20px;
        font-weight: 500;
        color: #857c73;

        span {
          font-size: 18px;
          font-weight: bold;
          color: #857c73;
        }
      }

      // .title {
      //   margin-top: 30px;
      //   font-size: 24px;
      //   text-align: center;
      //   font-weight: bold;
      //   color: #3a3a3a;
      // }
      // .line {
      //   margin-top: 14px;
      //   border-bottom: 2px solid #cccccc;
      // }
      .text1 {
        position: relative;
        margin-left: 80px;
        margin-top: 120px;
        font-size: 20px;
        font-weight: 500;
        color: #3a3a3a;

        span {
          margin-left: 10px;
          font-size: 24px;
          font-weight: bold;
        }
      }

      .text2 {
        position: relative;
        margin-top: 15px;
        font-size: 20px;
        font-weight: 500;
        color: #3a3a3a;
      }

      .bottomcontain {
        margin-top: 260px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .left {
          // border: 1px solid red;
          position: relative;

          .tip {
            font-size: 16px;
            font-weight: 500;
            color: #3a3a3a;
            margin-bottom: 10px;

            span {
              margin-left: 10px;
            }
          }
        }

        .right {
          position: relative;
          text-align: center;
          width: 200px;
          font-size: 30px;
          font-weight: 500;
          color: #3a3a3a;
        }
      }
    }

    .dialogfooter {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .bt {
        margin-right: 20px;
        width: 64px;
        height: 38px;
        line-height: 38px;
        text-align: center;
        background: #3d84ff;
        border-radius: 4px;
        color: white;
        user-select: none;
        cursor: pointer;
      }
    }
  }
}

.btgroup {
  // border: 1px solid red;
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  // align-items: center;
  color: #3d84ff;
  height: 60px;
  width: 100%;

  .bt {
    margin-top: 10px !important;
    width: 100%;
    height: 20px;
    margin: 0px;
    padding: 0px;
    border: none;
  }
}
</style>
